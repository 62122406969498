import {Pinch,TouchApp,AdsClick} from '@mui/icons-material'






const SvgHelp = () => {
  return (

      <>

          <div className='column is-12 is-flex is-justify-content-center yekan'>
              <Pinch/>
             <TouchApp/>
              <AdsClick/>
          </div>

          <div className='column is-12 is-flex is-justify-content-center yekan'>


              <p>
                  راهنما:
                  <span className="tag mx-3 clreight"> </span>
                  خریداری شده
                  <span className="tag mx-3 clrnine"> </span>
                  انتخاب شما


              </p>




          </div>



      </>
  )
}
export default SvgHelp;